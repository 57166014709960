.sidebar {
    &.sidebar-mini{
        .logo-mini{
            transform: translateX(75px);
        }
    } 
}


&[data-bs-theme="dark"]{
    .sidebar{
        .logo-main{
            .logo-normal{
                &.dark-normal{
                    transform: translateX(125px);
                }
            }
        }

        &.sidebar-mini{
            .dark-mini{
                &.logo-mini{
                    transform: translateX(110px);
                }
            }
        }
    }
}
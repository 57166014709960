.btn-group {
    direction: ltr;
}

.modal-header {
    .btn-close {
        margin: -.625rem auto -.625rem -.625rem;
    }
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-radius: 0 $input-border-radius $input-border-radius 0;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    margin-left: unset;
    border-radius: $input-border-radius 0 0 $input-border-radius;
}

.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4) {
    border-radius: 0 $input-border-radius $input-border-radius 0;
}

.btn-toolbar{
    .input-group>{
        .form-control{
            padding: 13px 0 13px 24px;
        }
    }
}